<template>
    <div>
        {{count}}
    </div>
</template>

<script>
import Bus from "../events/Bus";

export default {
    name: "CommentsCounter",
    props: ['counts'],
    data() {
        return {
            count: 0
        }
    },
    methods: {
        update(counter) {
            this.count = counter
        }
    },
    created() {
        this.count = this.counts;
        Bus.$on("changeCounter", this.update);
    }
}
</script>

<style>

</style>
