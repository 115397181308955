<template>
    <div class="comment">
        <div v-if="item.UserPicture" class="comment__picture" :style="{ backgroundImage: 'url(/file/' + item.UserPicture + ')' }"></div>
        <div v-else class="comment__picture">
            <font-awesome-icon icon="user" class="fa-sm" />
        </div>
        <div class="comment__body">
            <div v-if="item.State == 1">
                <div class="comment__text">
                    <span class="comment__username">{{ item.Nickname }}</span>
                    <span v-if="!isHidden">{{ item.Content }}</span>
                    <span class="comment__hidden" v-else>
                        Komentarz został ukryty z powodu zbyt wielu negatywnych ocen
                        <font-awesome-icon icon="eye" class="fa-sm" @click="isHidden = false" />
                    </span>
                </div>
                <div class="comment__options">
                    <div class="comment__date">
                        <span class="comment__options-text">{{ date }}</span>
                    </div>
                    <div v-if="isCommentEditable()" class="comment__edit-button comment__option" @click="showEditForm()">
                        <font-awesome-icon icon="pencil-alt" class="fa-sm" />
                        <span class="comment__options-text">Edytuj</span>
                    </div>
                    <div v-if="user.id != item.UserId" class="comment__replay-button comment__option" @click="showResponseForm()">
                        <font-awesome-icon icon="reply" class="fa-sm" />
                        <span class="comment__options-text">Odpowiedz</span>
                    </div>
                    <div class="comment__like-button comment__option" @click="vote(1)">
                        <font-awesome-icon v-if="item.Voted == -1 || item.Voted == 0" :icon="['far', 'thumbs-up']" class="fa-sm" />
                        <font-awesome-icon v-else icon="thumbs-up" class="fa-sm" />
                        <span class="comment__options-text">{{ this.item.Agree }}</span>
                    </div>
                    <div class="comment__notlike-button comment__option" @click="vote(0)">
                        <font-awesome-icon v-if="item.Voted == -1 || item.Voted == 1" :icon="['far', 'thumbs-down']" class="fa-sm" />
                        <font-awesome-icon v-else icon="thumbs-down" class="fa-sm" />
                        <span class="comment__options-text">{{ this.item.Disagree }}</span>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="comment__text comment__text--admin">
                    <span class="comment__username comment__username--admin">Administrator</span>
                    <span v-html="adminNote"></span>
                </div>
                <div class="comment__options">
                    <div class="comment__date">
                        <span class="comment__options-text">{{ date }}</span>
                    </div>
                </div>
            </div>
            <div class="comment__form" v-if="isUserLogged && responseForm">
                <div class="comment__picture" :style="{ backgroundImage: 'url(' + userPicture + ')' }"></div>
                <div class="w-100 position-relative d-flex align-items-center">
                    <el-input
                        @input="$v.comment.$touch()"
                        @keydown.enter.native.prevent="store()"
                        ref="comment"
                        type="textarea"
                        resize="none"
                        show-word-limit
                        placeholder="Napisz odpowiedź..."
                        v-model="comment"
                        :autosize="{ minRows: 1, maxRows: 4}"
                        :class="{ 'mr-2': true, 'is-error': $v.comment.$dirty && $v.comment.$invalid }"
                        autofocus
                        :disabled="saving"
                    ></el-input>
                    <font-awesome-icon icon="paper-plane" class="fa-sm comment__add-comment" @click="store()" />
                    <div v-if="saving" class="el-icon-loading saving-field"></div>
                </div>
            </div>
            <div class="comment__form comment__form--update" v-if="isUserLogged && editForm">
                <div class="w-100 position-relative d-flex align-items-center">
                    <el-input
                        @input="$v.comment.$touch()"
                        @keydown.enter.native.prevent="update()"
                        ref="comment"
                        type="textarea"
                        resize="none"
                        show-word-limit
                        placeholder="Edytuj komentarz..."
                        v-model="comment"
                        :autosize="{ minRows: 1, maxRows: 4}"
                        :class="{ 'mr-2': true, 'is-error': $v.comment.$dirty && $v.comment.$invalid }"
                        autofocus
                        :disabled="saving"
                    ></el-input>
                    <font-awesome-icon icon="paper-plane" class="fa-sm comment__add-comment" @click="update()" />
                    <div v-if="saving" class="el-icon-loading saving-field"></div>
                </div>
            </div>
            <el-collapse-transition>
                <div class="comment__responses" v-if="item.Responses && item.Responses.length && showResponses === true">
                    <comment
                        v-for="response in item.Responses"
                        :item="response"
                        :isUserLogged="isUserLogged"
                        :user="user"
                        :rootId="rootId"
                        :responseId="responseId"
                        :depth="depth+1"
                        :key="response.Id"
                    ></comment>
                </div>
            </el-collapse-transition>
            <div v-if="item.Responses && item.Responses.length && showResponses === false" class="comment__show-responses" @click="showResponses = true">
                <font-awesome-icon icon="share" class="fa-sm" />
                <span>{{ responsesCount }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import { minLength, maxLength, required } from "vuelidate/lib/validators";
import moment from "moment";
import "moment/locale/pl";
moment.locale("pl");

export default {
    name: "Comment",
    props: ["rootId", "item", "user", "isUserLogged", "responseId", "depth"],
    data() {
        return {
            comment: "",
            responseNickName: "",
            responseForm: false,
            showResponses: false,
            editForm: false,
            myVote: -1,
            isHidden: false,
            saving: false,
            message: false,
            notify: false
        };
    },
    computed: {
        userPicture() {
            if (this.isUserLogged === true && this.user.picture !== "") {
                return "/file/" + this.user.picture;
            }
            return "";
        },
        date() {
            let now = moment();
            let commentDate = moment(this.item.CreatedAt, "YYYY-MM-DD HH:mm:ss");
            let textDate = commentDate.from(now);
            if (this.item.UpdatedAt) {
                textDate += " (edytowany)";
            }
            return textDate;
        },
        responsesCount() {
            let count = this.item.Responses.length;
            let text = "odpowiedź";
            if (count > 1) {
                text = "odpowiedzi";
            }
            return this.item.Responses.length + " " + text;
        },
        adminNote() {
            return (
                "Komentarz użytkownika <strong>" +
                this.item.Nickname +
                '</strong> został usunięty z powodu naruszenia <strong><a style="color: #9b0303" target="_blank" href="/regulamin">' +
                this.item.AdminNote +
                " regulaminu</a></strong> "
            );
        }
    },
    methods: {
        store() {
            if (this.isUserLogged) {
                if (this.$v.$invalid === false) {
                    if (!this.saving) {
                        this.saving = true;

                        this.$recaptcha("login")
                            .then(token => {
                                this.$http
                                    .post("/comments/action/store", {
                                        reCaptchaToken: token,
                                        itemId: this.rootId,
                                        userId: this.user.id,
                                        userName: this.user.name,
                                        userPicture: this.user.picture,
                                        commentId: this.responseId, // response on comment
                                        responseId: this.item.Id, // response on comment response
                                        comment: this.comment
                                    })
                                    .then(({ data }) => {
                                        if (data.success === true) {
                                            if (this.depth > 0) {
                                                this.$parent.item.Responses.push(data.newComment);
                                            } else {
                                                this.item.Responses.push(data.newComment);
                                            }
                                            this.responseForm = false;

                                            this.showResponses = true;
                                            this.comment = "";
                                            this.$v.comment.$reset();

                                            if (!this.isUserLogged) {
                                                localStorage.setItem("sk.comment.nick", this.user.name);
                                            }
                                            if (this.message) {
                                                this.message.close();
                                            }
                                            this.message = this.$message({
                                                message: "Komentarz został dodany.",
                                                type: "success",
                                                duration: 5000,
                                                offset: 120
                                            });
                                            this.saving = false;
                                        } else {
                                            this.$confirm(data.message, "Błąd", {
                                                confirmButtonText: "Ok",
                                                type: "error",
                                                dangerouslyUseHTMLString: true,
                                                center: true,
                                                lockScroll: false,
                                                showCancelButton: false
                                            });
                                        }
                                        this.saving = false;
                                    });
                            })
                            .catch(error => {
                                if (this.message) {
                                    this.message.close();
                                }
                                this.message = this.$message({
                                    message: error,
                                    type: "error",
                                    duration: 10000,
                                    offset: 120
                                });
                                this.saving = false;
                            });
                    }
                } else {
                    if (this.message) {
                        this.message.close();
                    }
                    this.message = this.$message({
                        message: "Wpisz poprawny komentarz.",
                        type: "error",
                        duration: 5000,
                        offset: 120
                    });
                }
            } else {
                this.accessDenied();
            }
        },
        update() {
            if (this.isUserLogged) {
                if (this.$v.$invalid === false) {
                    if (!this.saving) {
                        this.saving = true;

                        this.$recaptcha("login")
                            .then(token => {
                                this.$http
                                    .post("/comments/action/update", {
                                        reCaptchaToken: token,
                                        id: this.item.Id,
                                        comment: this.comment
                                    })
                                    .then(({ data }) => {
                                        if (data.success == true) {
                                            this.item.Content = data.content;
                                            this.item.UpdatedAt = data.updatedAt;
                                        } else {
                                            this.$confirm(data.message, "Błąd", {
                                                confirmButtonText: "Ok",
                                                type: "error",
                                                dangerouslyUseHTMLString: true,
                                                center: true,
                                                lockScroll: false,
                                                showCancelButton: false
                                            });
                                        }

                                        this.editForm = false;
                                        this.saving = false;
                                        this.comment = "";
                                        this.$v.comment.$reset();
                                    });
                            })
                            .catch(error => {
                                if (this.message) {
                                    this.message.close();
                                }
                                this.message = this.$message({
                                    message: error,
                                    type: "error",
                                    duration: 10000,
                                    offset: 120
                                });
                                this.saving = false;
                            });
                    }
                } else {
                    if (this.message) {
                        this.message.close();
                    }
                    this.message = this.$message({
                        message: "Wpisz poprawny komentarz.",
                        type: "error",
                        duration: 5000,
                        offset: 120
                    });
                }
            } else {
                this.accessDenied();
            }
        },
        showResponseForm() {
            this.comment = "";
            this.$v.comment.$reset();
            if (this.isUserLogged) {
                this.responseForm = true;
                if (this.depth > 0) {
                    this.comment = "@" + this.item.Nickname + " ";
                }
            } else {
                this.accessDenied();
            }
        },
        showEditForm() {
            this.comment = "";
            this.$v.comment.$reset();
            if (this.isUserLogged) {
                if (this.isCommentEditable()) {
                    this.comment = this.item.Content;
                    this.$v.comment.$touch();

                    this.editForm = true;
                } else {
                    if (this.notify) {
                        this.notify.close();
                    }
                    this.notify = this.$notify({
                        title: "Brak dostępu",
                        dangerouslyUseHTMLString: true,
                        message: "Komentarz możesz edytować przez 30 minut od daty dodania.",
                        duration: 6000,
                        type: "warning",
                        position: "top-right"
                    });
                }
            } else {
                this.accessDenied();
            }
        },
        vote(vote) {
            if (this.isUserLogged) {
                if (!this.saving && this.item.UserId != this.user.id) {
                    this.saving = true;

                    this.$http
                        .post("/comments/action/vote", {
                            commentId: this.item.Id,
                            vote: vote
                        })
                        .then(({ data }) => {
                            if (data.success == true) {
                                if (data.vote == -1) {
                                    if (vote == 1) {
                                        this.item.Agree = parseInt(this.item.Agree) - 1;
                                    }
                                    if (vote == 0) {
                                        this.item.Disagree = parseInt(this.item.Disagree) - 1;
                                    }
                                    this.item.Voted = -1;
                                } else {
                                    if (vote == 1) {
                                        this.item.Agree = parseInt(this.item.Agree) + 1;
                                        if (this.item.Voted == 0) {
                                            this.item.Disagree = parseInt(this.item.Disagree) - 1;
                                        }
                                    }
                                    if (vote == 0) {
                                        this.item.Disagree = parseInt(this.item.Disagree) + 1;
                                        if (this.item.Voted == 1) {
                                            this.item.Agree = parseInt(this.item.Agree) - 1;
                                        }
                                    }
                                    this.item.Voted = vote;
                                }
                            }
                            this.saving = false;
                        });
                }
            } else {
                this.accessDenied();
            }
        },
        isCommentEditable() {
            let isEditable = true;

            if (!this.isUserLogged || this.user.id != this.item.UserId) {
                isEditable = false;
            }

            // 30 min from date adding
            let added = moment(this.item.CreatedAt, "YYYY-MM-DD HH:mm:ss");
            let now = moment();
            let diff = now.diff(added, "minutes");

            if (parseInt(diff) > 30) {
                return false;
            }

            return isEditable;
        },
        accessDenied() {
            if (this.notify) {
                this.notify.close();
            }
            this.notify = this.$notify({
                title: "Brak dostępu",
                dangerouslyUseHTMLString: true,
                message: "Ta opcja jest dostępna tylko dla zalogowanych użytkowników.",
                duration: 6000,
                type: "warning",
                position: "top-right"
            });
        }
    },
    validations: {
        comment: {
            required
        }
    },
    mounted() {
        let disagrees = parseInt(this.item.Disagree);
        let agrees = parseInt(this.item.Agree);
        if (disagrees > 10 && disagrees / agrees > 1.8) {
            this.isHidden = true;
        }
    }
};
</script>

<style scoped lang="scss">
.comment {
    display: flex;
    margin-bottom: 0.5rem;
    &__picture {
        width: 25px;
        min-width: 25px;
        height: 25px;
        background-color: #a3b1c3;
        border-radius: 50%;
        margin-right: 1rem;
        background-size: cover;
        flex-grow: 0;
        text-align: center;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }
    &__body {
        flex-grow: 1;
        width: 100%;
    }
    &__username {
        font-weight: bold;
        color: #14345d;
        margin-right: 5px;

        &--admin {
            color: #a80000;
        }
    }
    &__text {
        padding: 0.7rem;
        background-color: #e9eef5;
        font-size: 0.9rem;
        color: #6b6b6b;
        border-radius: 0.5rem;
        &--admin {
            background-color: #eecccc;
            color: #9b0303;
        }
    }

    &__options {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 0.2rem 0.7rem;
    }
    &__date {
        font-size: 0.8rem;
        color: #a3a3a3;
        font-size: 400;
        margin-right: 0.75rem;
    }
    &__option {
        font-size: 0.8rem;
        color: #7892b4;
        cursor: pointer;
        transition: color 0.2s ease-in-out;
        display: inline-block;
        vertical-align: middle;
        margin-right: 0.75rem;

        &:hover {
            color: #2c5080;
        }
    }
    &__like-button {
        color: #77ad77;
        &:hover {
            color: #397239;
        }
    }
    &__notlike-button {
        color: #cc8080;
        &:hover {
            color: #8b3a33;
        }
    }
    &__hidden {
        font-style: italic;
        svg {
            color: #7892b4;
            cursor: pointer;
        }
    }
    &__show-responses {
        font-size: 0.8rem;
        color: #7892b4;
        transition: color 0.3s ease-in-out;
        padding: 0.15rem 0;
        cursor: pointer;
        &:hover {
            color: #2c5080;
        }
    }
    &__responses {
        margin-top: 1rem;
    }
    &__form {
        display: flex;
        padding: 0.3rem 0;
    }
    .el-textarea {
        font-size: 13px;
    }
    .saving-field {
        position: absolute;
        top: 7px;
        right: -20px;
    }
    &__add-comment {
        cursor:pointer;
        color: #677d9b;
        transition: color 0.3s ease-in-out;
        &:hover {
            color: #14345d;
        }
    }
}
</style>
