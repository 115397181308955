/* Modules import */
import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import Vuelidate from 'vuelidate';
import ElementUI from 'element-ui';
import ElementUILocale from 'element-ui/lib/locale/lang/pl';
import '../sass/_elementUI.scss';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import Comments from './components/Comments.vue';
import CommentsCounter from './components/CommentsCounter.vue';


/* axios defaults */
axios.defaults.baseURL = window.location.protocol + "//" + window.location.host;

/* font awsome */
library.add(fas, far)
window.Vue = require('vue');
Vue.component('font-awesome-icon', FontAwesomeIcon)

// use modules
Vue.use(ElementUI, {locale: ElementUILocale});
Vue.use(VueAxios, axios);
Vue.use(Vuelidate);
Vue.use(VueReCaptcha, { siteKey: "6Lf4trAUAAAAAP_FD3iGd9bDA1FzkSsswj-vtOXe" })

const app = new Vue({
    el: "#app",
    components: {
        Comments,
        CommentsCounter
    }
})