<template>
    <div class="comments">
        <div class="comments__header">
            <div class="comments__count">{{ commentsCount }} komentarzy</div>
            <div v-if="isUserLogged">
                <template v-if="!savingFollow">
                    <font-awesome-icon v-if="followed" icon="heart" class="comments__thread comments__thread--watched" @click="unFollowThread" />
                    <font-awesome-icon v-else :icon="['far', 'heart']" class="comments__thread" @click="followThread"/>
                </template>
                <template v-else>
                    <div v-if="savingFollow" class="el-icon-loading"></div>
                </template>
            </div>
        </div>
        <el-collapse-transition>
            <div v-if="!loadingApp" class="comments__new-comment">
                <div v-if="isUserLogged === true" class="comments__picture" :style="{ backgroundImage: 'url(' + userPicture + ')' }"></div>
                <div v-else class="comments__picture">
                    <font-awesome-icon icon="user" class="fa-sm" />
                </div>
                <div class="w-100">
                    <el-input
                        size="small"
                        style="max-width: 250px;"
                        class="mb-2"
                        v-if="isUserLogged === false"
                        placeholder="Wpisz swój nick"
                        v-model="user.name"
                        @input="$v.user.name.$touch()"
                        :class="{ 'is-error': $v.user.name.$dirty && $v.user.name.$invalid }"
                        :disabled="saving"
                    ></el-input>
                    <div class="position-relative d-flex align-items-center">
                        <el-input
                            @input="$v.comment.$touch()"
                            @keydown.enter.native.prevent="addComment()"
                            type="textarea"
                            resize="none"
                            show-word-limit
                            placeholder="Napisz komentarz..."
                            v-model="comment"
                            :autosize="{ minRows: 1, maxRows: 4}"
                            :class="{'mr-2': true, 'is-error': $v.comment.$dirty && $v.comment.$invalid }"
                            :disabled="saving"
                        ></el-input>
                        <font-awesome-icon icon="paper-plane" class="fa-sm comments__add-comment" @click="addComment()" />
                        <div v-if="saving" class="el-icon-loading saving-field"></div>
                    </div>
                </div>
            </div>
        </el-collapse-transition>

        <div class="comments__list" v-loading="loadingComments" element-loading-text="Wczytuję komentarze...">
            <el-collapse-transition>
                <div v-if="comments.length">
                    <comment
                        v-for="comment in comments"
                        :item="comment"
                        :isUserLogged="isUserLogged"
                        :user="user"
                        :rootId="itemId"
                        :responseId="comment.Id"
                        :depth="0"
                        :key="comment.Id"
                    ></comment>
                </div>
            </el-collapse-transition>
            <div v-if="!comments.length" class="comments__no-comments">Skomentuj jako pierwszy.</div>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import Comment from "./Comment.vue";
import Bus from "../events/Bus";
import { minLength, maxLength, required, email } from "vuelidate/lib/validators";

export default {
    name: "Comments",
    components: {
        Comment
    },
    props: ["itemId", "userId"],
    data() {
        return {
            comments: [],
            commentsCount: 0,
            comment: "",
            isUserLogged: false,
            followed: false,
            user: {
                id: 0,
                name: "",
                picture: ""
            },
            loadingApp: true,
            loadingComments: true,
            saving: false,
            savingFollow: false,
            notify: false,
            message: false
        };
    },
    computed: {
        userPicture() {
            if (this.isUserLogged === true && this.user.picture !== "") {
                return "/file/" + this.user.picture;
            }
            return "";
        }
    },
    watch: {
        comments: {
            handler() {
                let count = 0;
                for (let comment in this.comments) {
                    if (this.comments.hasOwnProperty(comment)) {
                        count++;
                        for (let response in this.comments[comment].Responses) {
                            if (this.comments[comment].Responses.hasOwnProperty(response)) {
                                count++;
                            }
                        }
                    }
                }
                this.commentsCount = count;
                Bus.$emit("changeCounter", count);
            },
            deep: true
        }
    },
    methods: {
        get() {
            this.$http
                .post("/comments/action/index", {
                    itemId: this.itemId
                })
                .then(({ data }) => {
                    this.loadingComments = false;
                    Vue.set(this.$data, "comments", data);
                });
        },
        addComment() {
            if (this.isUserLogged) {
                this.store();
            } else {
                this.$confirm(
                    'Dodanie komentarza oznacza akceptację <a href="/regulamin" target="_blank">regulaminu</a>. Treści wulgarne, obraźliwe, naruszające regulamin będą usuwane.',
                    {
                        confirmButtonText: "Akceptuję",
                        cancelButtonText: "Nie Akceptuję",
                        type: "warning",
                        dangerouslyUseHTMLString: true,
                        center: true,
                        lockScroll: false
                    }
                )
                    .then(() => {
                        this.store();
                    })
                    .catch(() => {
                        if (this.message) {
                            this.message.close();
                        }
                        this.message = this.$message({
                            message: "Komentarz nie został dodany.",
                            type: "error",
                            duration: 5000,
                            offset: 120
                        });
                    });
            }
        },
        store() {
            if (this.$v.$invalid === false) {
                if (!this.saving) {
                    this.saving = true;

                    this.$recaptcha("login")
                        .then(token => {
                            this.$http
                                .post("/comments/action/store", {
                                    reCaptchaToken: token,
                                    itemId: this.itemId,
                                    userId: this.user.id,
                                    userName: this.user.name,
                                    userPicture: this.user.picture,
                                    comment: this.comment
                                })
                                .then(({ data }) => {
                                    if (data.success === true) {
                                        this.comments.unshift(data.newComment);
                                        this.comment = "";
                                        this.$v.comment.$reset();

                                        if (!this.isUserLogged) {
                                            localStorage.setItem("sk.comment.nick", this.user.name);
                                        }

                                        if (this.message) {
                                            this.message.close();
                                        }
                                        this.message = this.$message({
                                            message: "Komentarz został dodany.",
                                            type: "success",
                                            duration: 5000,
                                            offset: 120
                                        });
                                    } else {
                                        this.$confirm(data.message, "Błąd", {
                                            confirmButtonText: "Ok",
                                            type: "error",
                                            dangerouslyUseHTMLString: true,
                                            center: true,
                                            lockScroll: false,
                                            showCancelButton: false
                                        });
                                    }
                                    this.saving = false;
                                });
                        })
                        .catch(error => {
                            if (this.message) {
                                this.message.close();
                            }
                            this.message = this.$message({
                                message: error,
                                type: "error",
                                duration: 10000,
                                offset: 120
                            });
                            this.saving = false;
                        });
                }
            } else {
                if (this.message) {
                    this.message.close();
                }
                this.message = this.$message({
                    message: "Wpisz poprawny komentarz.",
                    type: "error",
                    duration: 5000,
                    offset: 120
                });
            }
        },
        getUser() {
            return new Promise((resolve, reject) => {
                this.$http.post("/comments/action/showuser").then(({ data }) => {
                    if (data) {
                        this.isUserLogged = true;
                        this.user.id = data.Id;
                        this.user.name = data.Username;
                        this.user.picture = data.Picture;
                        resolve(data);
                    } else {
                        this.isUserLogged = false;
                        let nick = localStorage.getItem("sk.comment.nick");
                        if (nick !== null) {
                            this.user.name = nick;
                        }

                        reject();
                    }
                });
            });
        },
        checkIsUserFollowThread() {
            this.$http
                .post("/comments/action/followed", {
                    itemId: this.itemId
                })
                .then(({ data }) => {
                    this.followed = data.followed;
                });
        },
        followThread() {
            if (this.isUserLogged && !this.savingFollow) {
                this.savingFollow = true;
                this.$http
                    .post("/comments/action/follow", {
                        itemId: this.itemId
                    })
                    .then(({ data }) => {
                        this.followed = data.followed;
                        this.savingFollow = false;
                        if (this.message) {
                            this.message.close();
                        }
                        this.message = this.$message({
                            message: "Obserwujesz ten wątek.",
                            type: "success",
                            duration: 5000,
                            offset: 120
                        });
                    });
            }
        },
        unFollowThread() {
            if (this.isUserLogged && !this.savingFollow) {
                this.savingFollow = true;
                this.$http
                    .post("/comments/action/unfollow", {
                        itemId: this.itemId
                    })
                    .then(({ data }) => {
                        this.followed = data.followed;
                        this.savingFollow = false;
                        if (this.message) {
                            this.message.close();
                        }
                        this.message = this.$message({
                            message: "Nie obserwujesz już tego wątku.",
                            type: "success",
                            duration: 5000,
                            offset: 120
                        });
                    });
            }
        }
    },
    validations() {
        let validations = {
            comment: {
                required
            }
        };
        if (this.isUserLogged === false) {
            validations.user = {
                name: {
                    required,
                    minLength: minLength(3),
                    maxLength: maxLength(30)
                }
            };
        }
        return validations;
    },
    mounted() {
        let promises = [this.get()];

        if (this.userId > 0) {
            promises.push(this.getUser());
            promises.push(this.checkIsUserFollowThread());
        } else {
            let nick = localStorage.getItem("sk.comment.nick");
            if (nick !== null) {
                this.user.name = nick;
            }
        }

        // All app data loaded
        Promise.all(promises).then(() => {
            this.loadingApp = false;
        });
    }
};
</script>

<style scoped lang="scss">
.comments {
    margin-bottom: 3rem;
    &__header {
        border-bottom: 2px solid #e9e9e9;
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__count {
        font-weight: 600;
        padding: 0.25rem 0;
        display: inline-block;
        border-bottom: 2px solid #14345d;
        margin-bottom: -2px;
    }
    &__thread {
        transition: color 0.3s ease-in-out;
        cursor: pointer;
        &:hover {
            color: #d81b1b;
        }
        &--watched {
            color: #d81b1b;
            &:hover {
                color: #df8888;
            }
        }
    }
    &__no-comments {
        color: #b8b8b8;
        font-weight: 300;
        text-align: center;
        padding: 1.5rem;
    }
    &__new-comment {
        padding-bottom: 1rem;
        display: flex;
        margin-bottom: 0.5rem;
    }
    &__picture {
        width: 25px;
        min-width: 25px;
        height: 25px;
        background-color: #a3b1c3;
        border-radius: 50%;
        margin-right: 1rem;
        background-size: cover;
        flex-grow: 0;
        text-align: center;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }
    &__add-comment {
        cursor:pointer;
        color: #677d9b;
        transition: color 0.3s ease-in-out;
        &:hover {
            color: #14345d;
        }
    }
}
.el-input,
.el-textarea {
    font-size: 13px;
}
.saving-field {
    position: absolute;
    top: 7px;
    right: -20px;
}
</style>
